<template>
  <div class='Test'>
  </div>
</template>

<script>
export default {
  name: "Test",
  mounted(){
    this.$img.open()
  },
}
</script>

<style scoped>
.Test {
  display: flex;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
}
</style>
